import React, { useState } from "react"
import Wrapper from "../components/wrapper";
import SectionTitle from "../components/sectionTitle";


const Hello = ({location}) => {

    return (
        <Wrapper
            customTitle={"Thank You | Website Design & Development Company in Kuala Lumpur, Malaysia | Appleseeds"}
            customDescription={"We are highly-skilled website designers and developers who are passionate in web design and development. Often, people wonder how a small team like ours can produce work in such caliber. And all we can say is, passion goes a long way."}
            customKeyword={"web design malaysia, website designing kl, webpage designer, creative website design, website design company, web page design, website agency, creative agency, hotel website design"}
            customOgImage={'/cover-about.jpg'}
            contactForm={true}
            slug={location.pathname}
        >
            <section>
                <div className="container mx-auto">
                    <SectionTitle
                        wrapperClassName="lg:w-full mt-20 py-12 lg:py-28 mb-5 h-min-96"
                        alignCenter={false}
                        title={['Thank you for contacting us,', <br className="hidden sm:block"/>, 'we will get back to you soon.']}
                        minititle="Contact Us"
                        type="h3"
                        invert={false}
                        childrenClassName=""
                        buttonText="Back to Home"
                        buttonType="btn-filled"
                        buttonLink="/"
                    >
                    </SectionTitle>
                </div>
            </section>
        </Wrapper>
    )
}

export default Hello;